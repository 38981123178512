import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Badge } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";


import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";


import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

import CodeIcon from '@material-ui/icons/Code';

import { Can } from "../components/Can";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../translate/i18n";

import { TuneRounded } from "@material-ui/icons";
import { isArray } from "lodash";
import toastError from "../errors/toastError";
import { useReducer } from "react";
import api from "../services/api";
import openSocket from "socket.io-client";
import { ReactComponent as CalendarIcon } from "bootstrap-icons/icons/calendar2-check.svg";
import { ReactComponent as WhatsApp } from "bootstrap-icons/icons/whatsapp.svg";
import { ReactComponent as Contact } from "bootstrap-icons/icons/person-vcard.svg";
import { ReactComponent as FlashIcon } from "bootstrap-icons/icons/lightning-charge-fill.svg";
import { ReactComponent as BookmarkIcon } from "bootstrap-icons/icons/bookmarks-fill.svg";
import { ReactComponent as Broadcast } from "bootstrap-icons/icons/broadcast-pin.svg";
import { ReactComponent as PersonFillGear } from "bootstrap-icons/icons/person-fill-gear.svg";
import { ReactComponent as InfoIcon } from "./FAQ.svg";


import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ListSubheader: {
        textTransform: "uppercase",
        fontWeight: "800",
        fontSize: "12px",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "5px",
        padding: 0,
    },
    listItem: {
        //borderRadius: 10,
        padding: "5px 8px",
        "&:hover": {
            backgroundColor: "#5a74bf",
            color: "#fff",
            backgroudOpacity: 0.2,
            "& svg": {
                color: "#fff",
            },
        },
    },
    listItemFaq: {
        //borderRadius: 10,
        padding: "5px 8px",
        color: "rgba(117, 10, 10, 0.94)",
        fontWeight: 700,        
        "&:hover": {
            backgroundColor: "#5a74bf",
            color: "#fff",
            backgroudOpacity: 0.2,
            "& svg": {
                color: "#fff",
            },
        },
    },
    subMenu: {
        paddingLeft: 15,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
    },
    logoutButton: {
        borderRadius: 10,
        marginTop: 10,
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        },
    },
    icons: {
        width: "20px",
        height: "20px"
    },
}));


function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(() => React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />)), [to]);

    return (<li>
        <ListItem button component={renderLink} className={className}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
        </ListItem>
    </li>);
}

/*const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
        const chats = action.payload;
        const newChats = [];

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id);
                if (chatIndex !== -1) {
                    state[chatIndex] = chat;
                } else {
                    newChats.push(chat);
                }
            });
        }

        return [...state, ...newChats];
    }

    if (action.type === "UPDATE_CHATS") {
        const chat = action.payload;
        const chatIndex = state.findIndex((u) => u.id === chat.id);

        if (chatIndex !== -1) {
            state[chatIndex] = chat;
            return [...state];
        } else {
            return [chat, ...state];
        }
    }

    if (action.type === "DELETE_CHAT") {
        const chatId = action.payload;

        const chatIndex = state.findIndex((u) => u.id === chatId);
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }

    if (action.type === "CHANGE_CHAT") {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat;
            }
            return chat;
        });
        return changedChats;
    }
};*/

const MainListItems = (props) => {
    const classes = useStyles();
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);

    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
    const history = useHistory();

    const [invisible, setInvisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam] = useState("");
    //const [chats, dispatch] = useReducer(reducer, []);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    /*useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);


    useEffect(() => {

        const socket = openSocket();

        socket.on("chatMessage", (data) => {
            if (data.action === "create") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
            if (data.action === "update") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
        });

        fetchChats();

        return () => {
            socket.disconnect();
        };
    }, [chats]);


    useEffect(() => {
        let unreadsCount = 0;
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id || chatUser.ownerId === user.id) {
                        unreadsCount += chatUser.unreads;
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false);
        } else {
            setInvisible(true);
        }
    }, [chats, user.id]);

    const fetchChats = async () => {
        try {
            const { data } = await api.get("/chats/", {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_CHATS", payload: data });
        }
        catch (err) {
            toastError(err);
        }
    }*/

    return (<div onClick={drawerClose}>
        <ListItemLink
            to="/tickets"
            primary={i18n.t("mainDrawer.listItems.tickets")}
            icon={<WhatsApp className={classes.icons} />}
            className={classes.listItem}
        />

        <ListItemLink
            to="/contacts"
            primary={i18n.t("mainDrawer.listItems.contacts")}
            icon={<Contact className={classes.icons} />}
            className={classes.listItem}
        />
        <ListItemLink
            to="/quickAnswers"
            primary={i18n.t("mainDrawer.listItems.quickAnswers")}
            icon={<FlashIcon className={classes.icons} />}
            className={classes.listItem}
        />
        {/*
        <ListItemLink
            to="/schedules"
            primary={'Agendamentos'}
            icon={<CalendarIcon className={classes.icons} />}
            className={classes.listItem}
        />*/}
        <ListItemLink
            to="/tags"
            primary={i18n.t("mainDrawer.listItems.tags")}
            icon={<BookmarkIcon className={classes.icons} />}
            className={classes.listItem}
        />
        {/*
        <ListItemLink
            to="/chats"
            primary="Chat Interno"
            icon={
                <Badge color="secondary" variant="dot" invisible={invisible}>
                    <QuestionAnswerOutlinedIcon />
                </Badge>
            }
            className={classes.listItem}

        />*/}
        <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (<>
                <Divider variant="middle" />
                <ListSubheader inset className={classes.ListSubheader}>
                    {drawerClose ? "Administração" : "Admin"}
                </ListSubheader>
                {/*
                <ListItem
                    dense
                    button
                    onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                    className={classes.listItem}
                >
                    <ListItemIcon>
                        <LocalOfferIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={i18n.t("mainDrawer.listItems.tags")}
                    />
                    {openCampaignSubmenu ? (
                        <ExpandLessIcon />
                    ) : (
                        <ExpandMoreIcon />
                    )}
                </ListItem>
                <Collapse
                    style={{ paddingLeft: 15 }}
                    in={openCampaignSubmenu}
                    timeout="auto"
                    unmountOnExit
                >
                    <List dense component="div" disablePadding>
                        <ListItem onClick={() => history.push("/tags")} button className={classes.listItem}>
                            <ListItemIcon>
                                <BookmarksIcon />
                            </ListItemIcon>
                            <ListItemText primary="Lista de tags" />
                        </ListItem>

                        <ListItem
                            onClick={() => history.push("/tags-reports")}
                            button
                            className={classes.listItem}
                        >
                            <ListItemIcon>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText primary="Relatórios" />
                        </ListItem>
                    </List>
                </Collapse>
                */}
                <ListItemLink
                    to="/"
                    primary="Dashboard"
                    icon={<DashboardOutlinedIcon />}
                    className={classes.listItem}
                />
                <ListItemLink
                    to="/connections"
                    primary={i18n.t("mainDrawer.listItems.connections")}
                    icon={<Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                        <Broadcast className={classes.icons} />
                    </Badge>}
                    className={classes.listItem}
                />
                <ListItemLink
                    to="/users"
                    primary={i18n.t("mainDrawer.listItems.users")}
                    icon={<PersonFillGear className={classes.icons} />}
                    className={classes.listItem}
                />
                <ListItemLink
                    to="/queues"
                    primary={i18n.t("mainDrawer.listItems.queues")}
                    icon={<AccountTreeOutlinedIcon />}
                    className={classes.listItem}
                />
                {user.name === "Suporte_Centerchat" &&
                    <ListItemLink
                        to="/api"
                        primary="API"
                        icon={
                            <CodeIcon />
                        }
                        className={classes.listItem}
                    />
                }

                <ListItemLink
                    to="/settings"
                    primary={i18n.t("mainDrawer.listItems.settings")}
                    icon={<SettingsOutlinedIcon />}
                    className={classes.listItem}
                />

                {user.name === "Suporte_Centerchat" &&
                    //ACRESCENTEI AO MENU LATERAL
                    <ListItemLink
                        to="/ControlPanel"
                        primary="Painel de Controle"//{i18n.t("mainDrawer.listItems.settings")}
                        icon={<TuneRounded />}
                        className={classes.listItem}
                    />
                }

            </>
            )}
        />
        <Can
            role={user.profile}
            perform="drawer-superv-items:view"
            yes={() => (
                <>
                    <Divider variant="middle" />
                    <ListSubheader inset className={classes.ListSubheader}>
                        {drawerClose ? "Supervisão" : "Superv"}
                    </ListSubheader>
                    <ListItemLink
                        to="/connections"
                        primary={i18n.t("mainDrawer.listItems.connections")}
                        icon={
                            <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                <SyncAltIcon />
                            </Badge>
                        }
                        className={classes.listItem}
                    />
                </>
            )}
        />
        <div style={{ marginTop: "auto" }}>
            <Divider variant="middle" />
            <ListItemLink
                to="/faq"
                primary={`Ajuda`}
                icon={<InfoIcon style={{ width: "40px", height: "40px", color: "rgba(117, 10, 10, 0.94)" }} />}
                className={classes.listItemFaq}
            />
        </div>
    </div>
    );
};

export default MainListItems;
